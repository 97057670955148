// Modify these according to your controls
const classes = {
  controls: 'glide__arrows',
  prevArrowSelector: '.glide__arrow--prev',
  nextArrowSelector: '.glide__arrow--next'
}

const arrowDisabler = (Glide, Components) => ({
  mount() {
    // Only in effect when rewinding is disabled
    if (Glide.settings.rewind) {
      return
    }

    Glide.on(['mount.after', 'run'], () => {
      // cycle through all control items. e.g; arrows or dots
      const controlItems = [...Components.Controls.items]
      const slides = [...Components.Html.slides]
      const currentIndex = Glide.index
      const { perView } = Glide.settings

      controlItems.forEach(controlItem => {
        // Filter for arrows
        if (controlItem.className.includes(classes.controls)) {
          // Should the arrows exist enable/disable
          const arrowPrev = controlItem.querySelector(classes.prevArrowSelector)
          if (arrowPrev && Components.Run.isStart()) {
            arrowPrev.setAttribute('disabled', 'true')
          } else {
            arrowPrev.removeAttribute('disabled')
          }

          const arrowNext = controlItem.querySelector(classes.nextArrowSelector)
          if (arrowNext && (Components.Run.isEnd() || currentIndex + perView >= slides.length)) {
            arrowNext.setAttribute('disabled', 'true')
          } else {
            arrowNext.removeAttribute('disabled')
          }
        }
      })
    })
  }
})

const ariaHiddenAddRemove = (Glide, Components) => ({
  mount() {
    Glide.on(['mount.after', 'run'], () => {
      setTimeout(() => {
        // adding timeout for slides to update the current active
        const slides = [...Components.Html.slides]
        const start = slides.findIndex(slide => slide.classList.contains('glide__slide--active'))
        const end = start + (Glide?.settings?.perView || 1)

        for (let i = 0; i < slides.length; i++) {
          if (i >= start && i < end) {
            slides[i].setAttribute('aria-hidden', false)
          } else {
            slides[i].setAttribute('aria-hidden', true)
          }
        }
      }, 500)
    })
  }
})

module.exports = {
  arrowDisabler,
  ariaHiddenAddRemove
}

import Glide from '@glidejs/glide'
import { arrowDisabler } from '../../../../../lib/glide'
import { rapidReady } from '../../../../../lib/utils/rapid'

export default (() => {
  class GlideCarousel {
    constructor(elem, instance) {
      if (!elem) return
      this.elem = elem
      this.instance = instance
      this.carouselElem = this.elem.querySelector('.m-nc__carousel')

      this.glideOptions = {
        perView: 7,
        perSwipe: '|',
        rewind: false,
        gap: 16,
        breakpoints: {
          1440: {
            perView: 4,
            peek: {
              before: 0,
              after: 50
            }
          },
          1280: {
            perView: 4,
            peek: {
              before: 0,
              after: 50
            }
          },
          1123: {
            perView: 4,
            peek: {
              before: 0,
              after: 50
            }
          },
          900: {
            gap: 10,
            perView: 6,
            peek: {
              before: 0,
              after: 50
            }
          },
          600: {
            gap: 10,
            perView: 4,
            peek: {
              before: 0,
              after: 50
            }
          }
        }
      }
      this.init()
    }

    init() {
      this.glideInstance = new Glide(this.carouselElem, this.glideOptions)
      this.glideInstance.mount({ arrowDisabler })
      this.glideInstance.on(['run.after'], () => {
        rapidReady(rapid => {
          rapid.refreshModule(this.elem.id)
        })
      })
    }
  }

  return GlideCarousel
})()

import Glide from '@glidejs/glide'
import { arrowDisabler } from '../../../../../lib/glide'
import { rapidReady } from '../../../../../lib/utils/rapid'

export default (() => {
  class GlideCarousel {
    constructor(elem, instance) {
      if (!elem) return
      this.elem = elem
      this.instance = instance
      this.carouselElem = this.elem.querySelector('.m-nc__carousel')

      // FYI perSwipe: '|' is not in the glide docs, but is supported and discussed in this issue
      // https://github.com/glidejs/glide/issues/346
      // If we need to support various carousel types in the future, these options should be updated so that we parse them from the DOM
      this.glideOptions = {
        perView: 7,
        perSwipe: '|',
        rewind: false,
        gap: 46,
        breakpoints: {
          1680: {
            gap: 50,
            perView: 5,
            peek: {
              before: 0,
              after: 160
            }
          },
          1440: {
            gap: 34,
            perView: 5,
            peek: {
              before: 0,
              after: 140
            }
          },
          1280: {
            gap: 42,
            perView: 5,
            peek: {
              before: 0,
              after: 140
            }
          },
          1123: {
            gap: 22,
            perView: 5,
            peek: {
              before: 0,
              after: 105
            }
          },
          900: {
            gap: 22,
            perView: 4,
            peek: {
              before: 0,
              after: 50
            }
          },
          600: {
            gap: 10,
            perView: 3,
            peek: {
              before: 0,
              after: 50
            }
          }
        }
      }
      this.init()
    }

    init() {
      this.glideInstance = new Glide(this.carouselElem, this.glideOptions)
      this.glideInstance.mount({ arrowDisabler })
      this.glideInstance.on(['run.after'], () => {
        rapidReady(rapid => {
          rapid.refreshModule(this.elem.id)
        })
      })
    }
  }

  return GlideCarousel
})()

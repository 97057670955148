import Glide from '@glidejs/glide'
import { arrowDisabler } from '../../../../../lib/glide'

const OLYMPIC_GLIDE_CUSTOM_OPTIONS = {
  CUSTOM_WIDTH: 220,
  translateWidthValue: 0,
  customScrollIndex: 0
}

const debounceForOlympicGlideUI = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default (() => {
  class GlideCarousel {
    constructor(elem, instance) {
      if (!elem) return
      this.elem = elem
      this.instance = instance
      this.carouselElem = this.elem.querySelector('.m-yss-hmc--o__carousel')
      this.OLYMPIC_GLIDE__LEFT_ARROW_CLASS = '.m-yss-hmc--o__controls--prev'
      this.OLYMPIC_GLIDE__RIGHT_ARROW_CLASS = '.m-yss-hmc--o__controls--next'

      this.glideOptions = {
        type: 'slider',
        hoverpause: false,
        bound: false,
        rewind: false,
        autoplay: false,
        perView: 5,
        perSwipe: '|',
        startAt: 0,
        animationDuration: 300,
        gap: 36,
        peek: {
          before: 0,
          after: 120
        },
        breakpoints: {
          1680: {
            gap: 16
          },
          1570: {
            gap: 16,
            perView: 4
          },
          1100: {
            gap: 16,
            perView: 3
          },
          750: {
            gap: 16,
            perView: 2
          },
          500: {
            gap: 16,
            perView: 1
          }
        }
      }

      this.init()
    }

    init() {
      this.glideInstance = new Glide(this.carouselElem, this.glideOptions)
      this.glideInstance.mount({ arrowDisabler, setCustomSlideWidth: this.setCustomSlideWidth })

      this.glideInstance.on(['mount.after', 'run.start'], () => {
        this.disabledGlideArrow(this.OLYMPIC_GLIDE__LEFT_ARROW_CLASS)
        this.enabledGlideArrow(this.OLYMPIC_GLIDE__RIGHT_ARROW_CLASS)
      })

      this.glideInstance.on('run.before', evt => {
        this.enabledGlideArrow(this.OLYMPIC_GLIDE__LEFT_ARROW_CLASS)
        this.enabledGlideArrow(this.OLYMPIC_GLIDE__RIGHT_ARROW_CLASS)

        const { direction, steps } = evt
        if (direction === '|') {
          if (steps === '<') {
            this.moveSliderLeft()
          } else if (steps === '>') {
            this.moveSliderRight()
          }
        }
      })

      this.glideInstance.on(
        'resize',
        debounceForOlympicGlideUI(e => {
          this.resizeEventHandler()
        }, 200)
      )

      this.sliderTranslateWidth(0)
    }

    glideConfig() {
      return new Promise((resolve, reject) => {
        const currentIndex = this.glideInstance.index
        const { perView, gap } = this.glideInstance.settings
        const slidesLength = this?.glideInstance?._c?.Html?.slides?.length
        resolve({ perView, gap, currentIndex, slidesLength })
      })
    }

    moveSliderRight() {
      this.glideConfig().then(response => {
        const { perView, gap, currentIndex, slidesLength } = response
        let nextIndex = currentIndex + perView
        if (currentIndex >= slidesLength) {
          nextIndex = currentIndex
        } else if (nextIndex >= slidesLength) {
          nextIndex = currentIndex + 1
        }
        this.updateCustomSlide(nextIndex, gap)
        if (slidesLength && currentIndex + 2 * perView >= slidesLength) {
          this.disabledGlideArrow(this.OLYMPIC_GLIDE__RIGHT_ARROW_CLASS)
        }
      })
    }

    moveSliderLeft() {
      this.glideConfig().then(response => {
        const { perView, gap, currentIndex } = response
        let nextIndex = currentIndex - perView
        nextIndex = nextIndex <= 0 ? 0 : nextIndex
        this.updateCustomSlide(nextIndex, gap)
      })
    }

    updateRightArrowUI() {
      this.glideConfig().then(response => {
        const { perView, currentIndex, slidesLength } = response
        if (slidesLength && currentIndex + perView >= slidesLength) {
          this.disabledGlideArrow(this.OLYMPIC_GLIDE__RIGHT_ARROW_CLASS)
        } else {
          this.enabledGlideArrow(this.OLYMPIC_GLIDE__RIGHT_ARROW_CLASS)
        }
      })
    }

    enabledGlideArrow(selector) {
      const arrow = document.querySelector(selector)
      arrow.style.cursor = 'pointer'
      if (arrow) {
        const arrow_path = arrow.querySelector('svg')
        arrow_path.classList.add('enable_arrow')
        arrow_path.classList.remove('disable_arrow')
        arrow.disabled = false
      }
    }

    disabledGlideArrow(selector) {
      const arrow = document.querySelector(selector)
      arrow.style.cursor = 'none'
      if (arrow) {
        const arrow_path = arrow.querySelector('svg')
        arrow_path.classList.add('disable_arrow')
        arrow_path.classList.remove('enable_arrow')
        arrow.disabled = true
      }
    }

    getTranslateWidthValue() {
      return OLYMPIC_GLIDE_CUSTOM_OPTIONS.translateWidthValue
    }

    setCustomSlideWidth(_Glide, Components, Events) {
      return {
        mount() {
          const { slides } = Components.Html
          slides.forEach((slide, index) => {
            slide.style.width = `${OLYMPIC_GLIDE_CUSTOM_OPTIONS.CUSTOM_WIDTH}px`
            slide.style['min-width'] = `${OLYMPIC_GLIDE_CUSTOM_OPTIONS.CUSTOM_WIDTH}px`
          })
        }
      }
    }

    setSliderZeroWidthToUI() {
      OLYMPIC_GLIDE_CUSTOM_OPTIONS.customScrollIndex = 0
      OLYMPIC_GLIDE_CUSTOM_OPTIONS.translateWidthValue = 0
      this.glideInstance.go('<<')
      document.querySelector('.m-yss-hmc--o-slides').style.transform = `translate3d(2px, 0px, 0px)`
    }

    setSliderWidthToUI(width) {
      OLYMPIC_GLIDE_CUSTOM_OPTIONS.translateWidthValue = width
      document.querySelector('.m-yss-hmc--o-slides').style.transform = `translate3d(-${width -
        2}px, 0px, 0px)`
    }

    getSliderWidth(nextIndex, gap) {
      const actualIndex = nextIndex
      const actualTranslateWidth =
        nextIndex * OLYMPIC_GLIDE_CUSTOM_OPTIONS.CUSTOM_WIDTH + nextIndex * gap
      return { actualTranslateWidth, actualIndex }
    }

    sliderTranslateWidth(nextIndex, gap) {
      if (nextIndex === 0) {
        this.setSliderZeroWidthToUI()
        return
      }

      const { actualTranslateWidth, actualIndex } = this.getSliderWidth(nextIndex, gap)
      OLYMPIC_GLIDE_CUSTOM_OPTIONS.customScrollIndex = actualIndex
      this.glideInstance.go(`=${OLYMPIC_GLIDE_CUSTOM_OPTIONS.customScrollIndex}`)
      this.setSliderWidthToUI(actualTranslateWidth)
    }

    updateCustomSlide(nextIndex, gap) {
      this.sliderTranslateWidth(nextIndex, gap)
    }

    resizeEventHandler() {
      this.glideInstance.mount({ setCustomSlideWidth: this.setCustomSlideWidth })
      this.glideConfig().then(response => {
        const { gap } = response

        if (OLYMPIC_GLIDE_CUSTOM_OPTIONS.customScrollIndex) {
          this.sliderTranslateWidth(OLYMPIC_GLIDE_CUSTOM_OPTIONS.customScrollIndex, gap)
        } else {
          this.sliderTranslateWidth(0)
        }
        this.updateRightArrowUI()
      })
    }
  }
  return GlideCarousel
})()
